import styled from '@emotion/styled'
import { Checkbox } from 'app/components/Common/Checkbox'
import { FormMessages } from 'app/components/Common/FormMessages'
import { Input } from 'app/components/Common/Input'
import { Spinner } from 'app/components/Common/Spinner'
import { Textarea } from 'app/components/Common/Textarea'
import { FlexBox } from 'app/components/Layout/FlexBox'
import {
  ContactsFormSenderBackend,
  FormData,
} from 'app/utils/ContactsFormSender'
import { useVocabularyData } from 'app/utils/vocabulary'
import { isLeft } from 'fp-ts/lib/These'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

const EMAIL_REGEX = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i

export interface Props {
  description?: string
  languageCode: string
  languagePrefix: string
  onContactsFormSendToBackend: ContactsFormSenderBackend
}

export const ContactsForm = memo(function ContactsForm({
  description,
  languageCode,
  languagePrefix,
  onContactsFormSendToBackend,
}: Props) {
  /*
   * Form data
   */
  const [isSendingForm, setIsSendingForm] = useState(false)
  const [displaySendErrorFeedback, setDisplaySendErrorFeedback] =
    useState(false)
  const [displaySendConfirmFeedback, setDisplaySendConfirmFeedback] =
    useState(false)

  const onValidSubmission = useCallback(
    async (data: FormData) => {
      if (isSendingForm) {
        return
      }

      setDisplaySendErrorFeedback(false)
      setDisplaySendConfirmFeedback(false)
      setIsSendingForm(true)
      const sendResult = await onContactsFormSendToBackend(data)
      setIsSendingForm(false)

      if (isLeft(sendResult)) {
        setDisplaySendErrorFeedback(true)
      } else {
        setDisplaySendConfirmFeedback(true)

        typeof window.gtag !== 'undefined' &&
          window.gtag('event', 'submit', {
            event_category: 'Website',
            event_label: 'Contacts Form',
          })

        typeof window._paq !== 'undefined' &&
          window._paq.push(['trackEvent', 'Website', 'Submit', 'Contacts Form'])
      }
    },
    [isSendingForm, onContactsFormSendToBackend],
  )

  const { register, formState, handleSubmit } = useForm<FormData>({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      offer_title:
        typeof window !== 'undefined'
          ? new URLSearchParams(window.location.search).get('offer') ||
            undefined
          : undefined,
      event_title:
        typeof window !== 'undefined'
          ? new URLSearchParams(window.location.search).get('event') ||
            undefined
          : undefined,
    },
  })

  const onSubmit = useMemo(
    () => handleSubmit(onValidSubmission),
    [handleSubmit, onValidSubmission],
  )

  /**
   * Handle scrolling to first field with errors
   */

  useEffect(() => {
    if (formState.errors) {
      const firstErrorElement = Object.values(formState.errors).find(
        (fieldError) => fieldError?.ref !== undefined,
      )?.ref as HTMLElement | undefined

      if (firstErrorElement && firstErrorElement.scrollIntoView) {
        firstErrorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }
  }, [formState.errors])

  return (
    <Container>
      <Form
        onSubmit={isSendingForm ? undefined : onSubmit}
        noValidate
        autoComplete={'off'}
      >
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
        <Spinner className={isSendingForm ? '' : 'hidden'} />
        <Wrapper row wrap space="between">
          <Field>
            <Label>{`${useVocabularyData('name', languageCode)} *`}</Label>
            <Input
              error={formState.errors.name !== undefined}
              isDirty={formState.dirtyFields.name}
              type="text"
              required={true}
              {...register('name', {
                required: true,
                validate: (name) => name.length > 2,
              })}
            />
          </Field>
          <Field>
            <Label>{`${useVocabularyData('lastname', languageCode)} *`}</Label>
            <Input
              error={formState.errors.lastname !== undefined}
              type="text"
              required={true}
              {...register('lastname', {
                required: true,
                validate: (lastname) => lastname.length > 2,
              })}
            />
          </Field>
          <Field>
            <Label>{`${useVocabularyData('email', languageCode)} *`}</Label>
            <Input
              error={formState.errors.email !== undefined}
              isDirty={formState.dirtyFields.email}
              type="email"
              required={true}
              {...register('email', {
                required: true,
                validate: (email) => EMAIL_REGEX.test(email),
              })}
            />
          </Field>
          <Field>
            <Label>{useVocabularyData('telephone', languageCode)}</Label>
            <Input type="text" {...register('phone')} />
          </Field>
          <Field className="large-type">
            <Label>{useVocabularyData('message', languageCode)}</Label>
            <Textarea required={false} {...register('message')} />
          </Field>
        </Wrapper>
        <Input type="hidden" {...register('offer_title')} />
        <Input type="hidden" {...register('event_title')} />
        <Checkboxes>
          <Checkbox
            error={formState.errors.privacy_policy !== undefined}
            label={useVocabularyData('form-policy-text', languageCode)}
            required={true}
            {...register('privacy_policy', {
              required: true,
            })}
          />
        </Checkboxes>
        {displaySendErrorFeedback ? (
          <FormMessages
            text={useVocabularyData('form-error-message', languageCode)}
            title={useVocabularyData('form-error-message-title', languageCode)}
            type="error"
          />
        ) : null}
        {displaySendConfirmFeedback ? (
          <FormMessages
            text={useVocabularyData('form-confirmation-message', languageCode)}
            title={useVocabularyData(
              'form-confirmation-message-title',
              languageCode,
            )}
          />
        ) : null}
        <Input
          name="submit"
          type="submit"
          value={useVocabularyData('send-request', languageCode)}
          variant="submit"
        />
      </Form>
    </Container>
  )
})

const Container = styled.section`
  margin: 0 auto 9rem;
  padding: 0 13.333vw;
  position: relative;

  @media (max-width: 1023px) {
    margin-bottom: 5.625rem;
    padding: 0 6.039vw;
  }

  @media (max-width: 375px) {
    padding: 0;
  }
`

const Form = styled.form`
  width: 100%;
  border: 0.625rem solid ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 4.75rem 10vw 5.625rem;
  position: relative;

  @media (max-width: 1023px) {
    padding: 3rem 6.039vw 3.75rem;
  }

  @media (max-width: 375px) {
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    padding-bottom: 0;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  text-align: center;
`

const Wrapper = styled(FlexBox)`
  margin-top: 2.9375rem;
  text-align: left;

  @media (max-width: 1023px) {
    margin-top: 1.5rem;
  }
`

const Field = styled.div`
  width: calc(50% - 1.5rem);
  margin-top: 2.5rem;
  &.large-type {
    width: 100%;
  }

  @media (max-width: 1023px) {
    width: calc(50% - 0.75rem);
    margin-top: 1.875rem;
  }
`

const Label = styled.label`
  display: block;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.25rem;
  margin-bottom: 0.375rem;
  text-transform: uppercase;
`

const Checkboxes = styled.div``
