import styled from '@emotion/styled'
import { memo } from 'react'

export interface Props {
  className?: string
}

export const Spinner = memo(function Spinner({ className }: Props) {
  return <Container className={className} />
})

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  transition: 0.2s;
  &.hidden {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }
  &:before {
    content: '';
    width: 60px;
    height: 60px;
    background: none;
    border: 6px solid ${({ theme }) => theme.colors.variants.primaryLight1};
    border-top-color: transparent;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -20px 0 0 -20px;
    animation: spin 1s infinite linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`
