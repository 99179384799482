import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  className?: string
  src?: string
  srcSet?: string
  alt?: string
  width?: number
  height?: number
}

export const Image = memo(function Image({
  className,
  src,
  srcSet,
  alt,
  width,
  height,
}: Props) {
  return (
    <Container
      className={className}
      src={src}
      srcSet={srcSet}
      alt={alt}
      width={width}
      height={height}
    />
  )
})

const Container = styled.img``
